var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import AsyncStatus from "../../utils/async";
import ImagesUploader from "../ui/form/ImagesUploader.vue";
import { allCountriesOptions, genderOptions } from "../../data";
import addStudent from "../../api/mutations/addStudent";
import NotificationsMixin from "../ui/NotificationsMixin";
import mixins from "vue-typed-mixins";
import { resetFormValidation } from "../../utils/form";
import { createUserEmailAddressAvailable } from "../../validation/userEmailAddressAvailable";
function createEmptyFormValues() {
    return {
        firstName: "",
        middleName: "",
        lastName: "",
        gender: null,
        dateOfBirth: undefined,
        countryOfBirth: null,
        emailAddress: "",
        confirmEmailAddress: "",
        mobileNumber: "",
        weChatId: "",
        educationInstitute: "",
        courseStartDate: undefined,
        courseCategory: "",
        letterOfOffer: [],
        copyOfPassport: [],
    };
}
export default mixins(NotificationsMixin).extend({
    components: {
        ImagesUploader: ImagesUploader,
    },
    data: function () {
        return {
            status: new AsyncStatus(),
            form: createEmptyFormValues(),
            genderOptions: genderOptions,
            countryOptions: allCountriesOptions,
        };
    },
    created: function () {
        this.$validator.extend("userEmailAddressAvailable", createUserEmailAddressAvailable(this.$apollo, this.$t.bind(this)));
    },
    methods: {
        onSubmit: function () {
            var _this = this;
            this.status.run(this.$validator.validate().then(function (valid) {
                var _a = _this.form, 
                /* eslint-disable @typescript-eslint/no-unused-vars */
                confirmEmailAddress = _a.confirmEmailAddress, 
                /* eslint-enable @typescript-eslint/no-unused-vars */
                dateOfBirth = _a.dateOfBirth, courseStartDate = _a.courseStartDate, gender = _a.gender, countryOfBirth = _a.countryOfBirth, input = __rest(_a, ["confirmEmailAddress", "dateOfBirth", "courseStartDate", "gender", "countryOfBirth"]);
                // date stuff is for typing
                if (!valid || !dateOfBirth || !countryOfBirth) {
                    return;
                }
                return _this.$apollo
                    .mutate(addStudent(__assign({ dateOfBirth: dateOfBirth, courseStartDate: courseStartDate, countryOfBirth: countryOfBirth, gender: gender }, input)))
                    .then(function () {
                    _this.form = createEmptyFormValues();
                    resetFormValidation(_this);
                    _this.displaySuccessMessage(_this.$t("New student created"));
                });
            }));
        },
    },
    metaInfo: function () {
        return {
            title: this.$t("Add students").toString(),
        };
    },
});
