var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        uploadDirectory: {
            type: String,
            required: true,
        },
        value: {
            type: Array,
            required: true,
        },
    },
    data: function () {
        return {
            isRunningInternalRemove: false,
            dropzoneOptions: {
                acceptedFiles: "image/*,application/pdf",
                dictDefaultMessage: "Drag & drop file here or click to upload",
                addRemoveLinks: true,
            },
        };
    },
    computed: __assign({ dropzone: function () {
            var ref = this.$refs.dropzone;
            if (!ref) {
                throw new Error("No dropzone component");
            }
            return ref;
        }, dropzoneS3Options: function () {
            return {
                signingURL: this.s3SigningUrl,
                params: { uploadDirectory: this.uploadDirectory },
                sendFileToServer: false,
            };
        } }, mapState(["s3SigningUrl"])),
    watch: {
        value: function (newVal) {
            if (!newVal || newVal.length === 0) {
                this.isRunningInternalRemove = true;
                this.dropzone.removeAllFiles(true);
                this.isRunningInternalRemove = false;
            }
        },
    },
    methods: {
        onFileError: function (errorMessage) {
            console.log("S3 upload error: ".concat(errorMessage));
        },
        onFileUploaded: function (encodedS3Url) {
            // decode the location as AWS replaces slash with %2F in key.
            var s3Url = decodeURIComponent(encodedS3Url);
            this.$emit("input", __spreadArray(__spreadArray([], this.value, true), [s3Url], false));
        },
        onFileRemoved: function (file) {
            if (this.isRunningInternalRemove) {
                return;
            }
            var fullUrl = file.s3Url + file.s3Signature.key;
            var index = this.value.indexOf(fullUrl);
            if (index === -1) {
                // Cancelled during upload
                return;
            }
            this.$emit("input", __spreadArray(__spreadArray([], this.value.slice(0, index), true), this.value.slice(index + 1), true));
        },
    },
});
